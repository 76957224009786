// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "jquery"
// import "channels"
// import 'bootstrap'
// // import "custom/jquery.validate"
// // import "custom"
// // import 'jquery-validation'
// // import "jquery-validation/dist/jquery-validation"
//
// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
//
// // import modernizr from 'modernizr';
//
// // require("jquery");
//
// // require("channels/jquery.min")
// // require("channels/popper.min")
// require("channels/bootstrap.min")
// require("channels/select2.min")
// // require("custom")
// require("custom/jquery.validate.min")
//
// // require("channels/toastr.min")
//
//
// global.toastr = require("toastr")
// toastr.options = {
//   "closeButton": true
// }
// // $('form').validate()
// $(document).ready(function(){
//   $('form').validate()
// })
// global.validate =
// //
// window.jQuery = $;
// window.$ = $;

// $(document).ready(function(){
//         $(".show-toast").click(function(){
//             $("#myToast").toast('show');
//         });
//     });
// ******************************************************************commented************************************************************************

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
global.$ = global.JQuery = JQuery;

import "bootstrap";
// import 'select2'
// import "../custom/select2.min"
global.owlCarousel = require('owl.carousel')

require("@rails/ujs").start()
require("turbolinks").start()
    //require("@rails/activestorage").start()
require("channels")
    // require("custom")
    // require("channels/bootstrap.min")
    // require("channels/select2.min")
import LocalTime from "local-time"
LocalTime.start()
// global.select2 = require("select2")
global.toastr = require("toastr")

toastr.options = {
        "closeButton": true,
        "preventDuplicates": true,
    }
// require("moment")
// global.owlCarousel = require('owl.carousel')
global.datepicker = require('bootstrap-datepicker')
// global.validate = require('jquery-validation')

    // import "custom/jquery.validate.min";
// require("custom/jquery.validate.min")
require("custom/maxlength")
require("custom/star-rating.min")
// require("custom/theme.min")
import "../channels/select2.min"
import "../custom/jquery.validate.min"
// import "../custom/bootstrap-datepicker.js"
// import "../channels/bootstrap-datepicker"
// require("custom/card")
    // require("custom/select2.min")

// import 'select2/dist/css/select2.css'
// import 'custom/select2.min'
// pwcheck
// $.validator.addMethod("pwcheck", function(value) {
//   return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(value) // consists of only these
//       && /[a-z]/.test(value)       && /\d/.test(value)
// });
// regex method for email& password validation

// $(function () {
//   $('[data-toggle="tooltip"]').tooltip()
// })

$.validator.addMethod(
    "regex",
    function(value, element, regexp) {
        var re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
    },
    "Please enter a valid email address.",
);

$.validator.addMethod(
    "referral-regex",
    function(value, element, regexp) {
        var re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
    },
    "Please enter a valid referral code.",
);
$.validator.addMethod( "notEqualTo", function( value, element, param ) {
    return this.optional( element ) || !$.validator.methods.equalTo.call( this, value, element, param );
}, "Please enter a different value, values must not be the same." );

document.addEventListener("turbolinks:load", function() {
    //customer register form
      $('#register').validate({
        rules: {
            email: {
                required: true,
                "regex": /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            },
            password: {
                required: true,
                minlength: 8,
                maxlength: 15,
                regex: /^(?=.*\d)(?=.*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,15}$/,
            }
        },
        messages: {
            email: {
                required: "Email address cannot be blank.",
                "regex": "Please enter a valid email address.",
            },
            password: {
                required: "Password cannot be blank.",
                minlength: "Password must contain 8+ characters and at least one number.",
                "regex": "Password must contain 8+ characters and at least one number.",
                maxlength: "Maximum length should be 15 characters."
            }
        }
    });
    // sign-in form
    $("#sign-in").validate({
        rules: {
            email: {
                required: true,
                "regex": /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            },
            password: {
                required: true,
            }
        },
        messages: {
            email: {
                required: "Email address cannot be blank.",
                "regex": "Please enter a valid email address.",
            },
            password: {
                required: "Password cannot be blank.",
            }
        }
    });

    // customer setup profile form
    $('#setup').validate({
        rules: {
            first_name: {
                required: true,
                maxlength: 30,
            },
            last_name: {
                required: true,
                maxlength: 30,
            },
            email: {
                required: true,
                "regex": /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            },
            referral_code:{
                required: false,
                maxlength: 15,
                "referral-regex": /^[A-Z0-9!@#$&()\\\-%()*`=.+^_~,><{}/\"]*$/,
            },
            mobile_no: {
                required: false,
                minlength: 10,
                maxlength: 10,
            },
            is_accepted: {
                required: true,
            },
        },
        messages: {
            first_name: {
                required: "First name cannot be blank.",
                maxlength: "Maximum length should be 30 characters.",
            },
            last_name: {
                required: "Last name cannot be blank.",
                maxlength: "Maximum length should be 30 characters.",
            },
            email: {
                required: "Email address cannot be blank.",
                email: "Please enter a valid email address.",
            },
            referral_code: {
                maxlength: "Maximum length should be 15 characters.",
            },
            mobile_no: {
                minlength: "Mobile number must be 10 characters.",
                maxlength: "user should not enter more than 10 digit.",
            },
            is_accepted: {
                required: "You must accept the Terms and Conditions and Privacy Policy to continue.",
            },
        },

    });
    //  customer change password form
    $("#change-password-form").validate({

        rules: {
            current_password: {
                required: true,
            },
            new_password: {
                required: true,
                // pwcheck: true,
                minlength: 8,
                maxlength: 15,
                'notEqualTo': "#current_password",
                "regex": /^(?=.*\d)(?=.*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,15}$/
                // pwcheck: true,
            },
            confirm_new_password: {
                required: true,
                equalTo: "#new_password",
                maxlength: 15,
                minlength: 8,
                "regex": /^(?=.*\d)(?=.*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,15}$/

            },
        },
        messages: {
            current_password: {
                required: "Current password cannot be blank.",
            },
            new_password: {
                required: "New password cannot be blank.",
                'notEqualTo': "New password must be different from the current password",
                minlength: "New Password must contains 8+ characters and at least one number.",
                "regex": "New Password must contain 8+ characters and at least one number.",
                maxlength: "Maximum length should be 15 characters."
            },
            confirm_new_password: {
                required: "Confirm password cannot be blank.",
                equalTo: "New Password & Confirm password must be same.",
                maxlength: "Maximum length should be 15 characters."
            }
        }

    });
    // forgot password form
    $('#forgot-password').validate({
        rules: {
            forgot_password_email: {
                required: true,
                "regex": /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            },
        },
        messages: {
            forgot_password_email: {
                required: "Email address cannot be blank",
                forgot_password_email: "Please enter a valid email address.",
            },
        },

    });
    // customer edit profile form
    $('#edit_profile').validate({
        rules: {
            first_name: {
                required: true,
                minlength: 2,
                maxlength: 30,
            },
            last_name: {
                required: true,
                minlength: 2,
                maxlength: 30,
            },
            mobile_no: {
                required: false,
                minlength: 10,
                maxlength: 10,
            },
            email: {
                required: true,
                "regex": /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            },
        },
        messages: {
            first_name: {
                required: "First name cannot be blank.",
                maxlength: "Maximum length should be 30 charecters.",
            },
            last_name: {
                required: "Last name cannot be blank.",
                maxlength: "Maximum length should be 30 charecters.",
            },
            mobile_no: {
                minlength: "Mobile number must be 10 characters.",
                maxlength: "user should not enter more than 10 digit.",
            },
            email: {
                required: "Email address cannot be blank.",
                email: "Please enter a valid email address.",
            },
        },

    });
    // $('.select2').select2();
    $('#otp-form').validate();
    // $('#step_1_form').validate();
    // $('#step_2_form').validate();
    $('form').validate()
    $('#review_job').validate()
    $('#step_1_form').validate({
      rules: {
          job_type_udid: {
              required: true,
          },
      },
      messages: {
          job_type_udid: {
            required: "Please select Job Name",
          },
        }
    })
    // $('#step_2_form').validate({
    //   focusInvalid: true,
    //   invalidHandler: function(form, validator) {
    //     if (!validator.numberOfInvalids())
    //         return;
    //
    //     $('html, body').animate({
    //     scrollTop: $(validator.errorList[0].element).offset().top
    //     }, 2000);
    //   },
    //   rules: {
    //       job_type_service: {
    //           required: true,
    //       },
    //       'job_attribute_choice[]':{
    //         required: true,
    //       }
    //   },
    //   messages: {
    //       job_type_service: {
    //         required: "Please select Delivery Type",
    //       },
    //       'job_attribute_choice[]': {
    //         required: "Please select Job Attributes",
    //       }
    //   }
    // })
    // console.log($('#step_2_form'));
    // if($('#step_2_form').valid()){
    //   alert(3)
    // }else{
    //   alert(4)
    // }
});

$(document).ready(function(){
  var is_mobile = check_if_mobile_or_ipad()
  if(is_mobile){
    $('.tooltip-text').show()
    $('.tooltip-icon').hide()
  }else{
    $('.tooltip-text').hide()
    $('.tooltip-icon').show()
  }
  // $('#sync1').owlCarousel({
  //   loop:true,
  //   margin:10,
  //   nav:false,
  //   navigation : false,
  //   responsive:{
  //     0:{
  //       items:1
  //     },
  //     600:{
  //       items:1
  //     },
  //     1000:{
  //       items:1
  //     }
  //   }
  // })
  // $('#sync2').owlCarousel({
  //   loop:true,
  //   margin:10,
  //   nav:false,
  //   navigation : false,
  //   responsive:{
  //     0:{
  //       items:1
  //     },
  //     600:{
  //       items:1
  //     },
  //     1000:{
  //       items:1
  //     }
  //   }
  // })
})

document.addEventListener("turbolinks:load", function() {
  var is_mobile = check_if_mobile_or_ipad()
  if(is_mobile){
    $('.tooltip-text').show()
    $('.tooltip-icon').hide()
  }else{
    $('.tooltip-text').hide()
    $('.tooltip-icon').show()
  }
  // alert(1)
  $('#sync1').owlCarousel({
    loop:false,
    margin:10,
    nav:true,
    navigation : true,
    dots: false,
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive:{
      0:{
        items:1
      },
      600:{
        items:1
      },
      1000:{
        items:1
      }
    }
  })
  $('#sync2').owlCarousel({
    loop:false,
    margin:10,
    nav:true,
    navigation : true,
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    dots: false,
    responsive:{
      0:{
        items:1
      },
      600:{
        items:1
      },
      1000:{
        items:1
      }
    }
  })
  $('.owl-carousel').owlCarousel({
    loop:false,
    margin:10,
    nav:true,
    navigation : true,
    dots: false,
    // navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive:{
      0:{
        items:1
      },
      600:{
        items:1
      },
      1000:{
        items:1
      }
    }
  })
  // $('#sync4').owlCarousel({
  //   loop:true,
  //   margin:10,
  //   nav:false,
  //   navigation : false,
  //   responsive:{
  //     0:{
  //       items:1
  //     },
  //     600:{
  //       items:1
  //     },
  //     1000:{
  //       items:1
  //     }
  //   }
  // })
})



$(document).ajaxStart(function(){
  $('#loader').css('display', 'flex')
})
$(document).ajaxStop(function(){
  $('#loader').css('display', 'none')
})
// $('.select_addon').select2({
//   placeholder: 'Select Service',
//   allowClear: true
// })



function hasNetwork(online) {
  if (online) {
    // console.log("onLine")
  } else {
    toastr.error("No internet connection.")
  }
}
$(document).on('turbolinks:load beforeunload click', () => {
  hasNetwork(navigator.onLine);
  window.addEventListener("online", () => {
    hasNetwork(true);
  });
  window.addEventListener("offline", () => {
    hasNetwork(false);
  });
});
 function check_if_mobile_or_ipad(){
    // var isMobile = false; //initiate as false
    // device detection
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // isMobile = true;
        return true
    }
  }
// window.addEventListener('DOMContentLoaded', () => {
//   // $('.select2').select2()
// })
